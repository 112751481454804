import type { Element } from '~/tenset-components'
import { Icon, IconName } from '~/tenset-components'
import urls from '~/utils/urls'

export const communityElements: Element[] = [
  {
    name: 'Twitter',
    to: 'https://twitter.com/Tenset_io',
    icon: <Icon name={IconName.TwitterMono} />,
  },
  {
    name: 'Twitter Security',
    to: 'https://twitter.com/tenset_security',
    icon: <Icon name={IconName.TwitterMono} />,
  },
  {
    name: 'Community',
    to: 'https://t.me/tenset_io_eng',
    icon: <Icon name={IconName.TelegramCommunity} />,
  },
  {
    name: 'Announcements',
    to: 'https://t.me/tensetio',
    icon: <Icon name={IconName.TelegramAnnouncements} />,
  },
  {
    name: 'LinkedIn',
    to: 'https://www.linkedin.com/company/tenset',
    icon: <Icon name={IconName.LinkedinMono} />,
  },
  {
    name: 'CoinGecko',
    to: 'https://www.coingecko.com/en/coins/tenset',
    icon: <Icon name={IconName.CoinGeckoMono} />,
  },
  {
    name: 'Line',
    to: 'https://line.me/ti/g2/QBYVIHDNVY_InQzC-r7kjw',
    icon: <Icon name={IconName.LineMono} />,
  },
  {
    name: 'KakaoTalk',
    to: 'https://open.kakao.com/o/gIQdeT2c',
    icon: <Icon name={IconName.KakaoTalkMono} />,
  },
  {
    name: 'YouTube',
    to: 'https://www.youtube.com/channel/UC5RxXdhQ6BkbB4lfTMkWGhA',
    icon: <Icon name={IconName.YoutubeMono} />,
  },
  {
    name: 'footer.community.verify-media',
    to: '/verification',
    icon: <Icon name={IconName.Verification} />,
  },
  {
    name: 'footer.community.zealy-rewards',
    to: '/zealy-rewards',
    icon: <Icon name={IconName.ZealyLogo} />,
  },
]

export const applyElements: Element[] = [
  {
    name: 'common:launch-your-project',
    to: urls.launchYourProjectLink,
  },
]

export const legalElements: Element[] = [
  {
    name: 'footer.legal.terms',
    to: '/assets/documents/tenset-terms-and-conditions.pdf',
    reloadDocument: true,
  },
  {
    name: 'footer.legal.data',
    to: '/assets/documents/tenset-privacy-and-cookies-policy.pdf',
    reloadDocument: true,
  },
]
