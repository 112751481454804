import { useLoaderData } from '@remix-run/react'
import { useEffect } from 'react'

import type { RootLoader } from '~/root'

declare global {
  interface Window {
    $crisp: []
    CRISP_WEBSITE_ID: string
  }
}

export function Chatbox() {
  const {
    env: { CRISP_WEBSITE_ID },
  } = useLoaderData<RootLoader>()

  useEffect(() => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID
    ;(() => {
      const d = document
      const s = d.createElement('script')
      s.src = 'https://client.crisp.chat/l.js'
      s.async = true
      d.querySelectorAll('head')[0].append(s)
    })()
  })

  return null
}
