import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { applyElements, communityElements, legalElements } from '~/data/footer'
import { Namespace } from '~/i18n'
import useLocalizePathname from '~/i18n/use-localize-pathname'
import {
  Icon,
  IconName,
  Footer as FooterComponent,
  type Element,
} from '~/tenset-components'
import { isExternalLink } from '~/tenset-components/utils'

interface AddTranslationProps {
  elements: Element[]
  t: TFunction
}

export function Footer() {
  const { t } = useTranslation([Namespace.COMMON])
  const localizePathname = useLocalizePathname()

  const lists = [
    {
      title: t('footer.community.title'),
      elements: communityElements.map(element => {
        if (!isExternalLink(element.to)) {
          return {
            ...element,
            to: localizePathname(element.to),
            name: t(element.name),
          }
        }

        return element
      }),
    },
    {
      title: t('footer.apply.title'),
      elements: addTranslation({ elements: applyElements, t }),
    },
    {
      title: t('footer.legal.title'),
      elements: addTranslation({ elements: legalElements, t }),
    },
  ]

  return (
    <FooterComponent
      lists={lists}
      icon={<Icon name={IconName.TensetLogoMonoLabel} />}
    />
  )
}

const addTranslation = ({ elements, t }: AddTranslationProps) => {
  return elements.map(element => {
    return {
      ...element,
      name: t(element.name),
    }
  })
}
