import { useNavigation } from '@remix-run/react'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

// https://dev.to/remix-run-br/creating-a-github-like-progress-bar-for-your-remix-app-153l
export function PageLoadingIndicator() {
  const navigation = useNavigation()

  const ref = useRef<HTMLDivElement>(null)
  const [animationComplete, setAnimationComplete] = useState(true)

  const [isChangingRoute, setIsChangingRoute] = useState(false)

  useEffect(() => {
    if (navigation.state === 'idle') {
      setIsChangingRoute(false)

      return
    }

    if (!navigation.location) return

    const { pathname: currentPathname } = window.location
    const { pathname: navigationPathname } = navigation.location

    // Check if only the pathname has changed
    setIsChangingRoute(currentPathname !== navigationPathname)
  }, [navigation.state, navigation.location])

  useEffect(() => {
    if (!ref.current) return

    if (isChangingRoute) setAnimationComplete(false)

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished)
    ).then(() => !isChangingRoute && setAnimationComplete(true))
  }, [isChangingRoute])

  return (
    <div
      role="progressbar"
      aria-hidden={!isChangingRoute}
      aria-valuetext={isChangingRoute ? 'Loading' : undefined}
      className="h-1.5 animate-pulse"
    >
      <div
        ref={ref}
        className={clsx(
          'h-full bg-gradient-to-r from-[#9031DE] to-[#DD4FCF] transition-all duration-500 ease-in-out',
          !isChangingRoute &&
            animationComplete &&
            'w-0 opacity-0 transition-none',
          navigation.state === 'submitting' && 'w-4/12',
          navigation.state === 'loading' && 'w-10/12',
          navigation.state === 'idle' && !animationComplete && 'w-full'
        )}
      />
    </div>
  )
}
