import type { NavigationData } from '~/tenset-components'
import { HrefType } from '~/tenset-components'

export const navigationData: NavigationData[] = [
  {
    label: 'Homepage',
    href: {
      type: HrefType.Internal,
      url: '/',
      withLocale: true,
    },
  },
  {
    label: 'Business',
    subItems: [
      {
        label: 'Smart contract audits',
        href: {
          type: HrefType.Internal,
          url: '/smart-contract-audits',
          withLocale: true,
        },
      },
      {
        label: 'Cyberstorm',
        href: {
          type: HrefType.Internal,
          url: '/cyberstorm',
          withLocale: true,
        },
      },
      {
        label: 'Smart contract development',
        href: {
          type: HrefType.Internal,
          url: '/smart-contract-development',
          withLocale: true,
        },
      },
      {
        label: 'UI/UX for crypto',
        href: {
          type: HrefType.Internal,
          url: '/ui-ux-for-crypto',
          withLocale: true,
        },
      },
    ],
  },
  {
    label: '10set Utilities',
    subItems: [
      {
        label: 'Gem Launch Platform',
        href: {
          type: HrefType.Internal,
          url: '/gem-launch-platform',
          withLocale: true,
        },
      },
      {
        label: 'NFT Marketplace',
        href: {
          type: HrefType.External,
          url: 'https://marketplace.tenset.io/',
          withLocale: false,
        },
      },
      {
        label: 'Infinity AirDrop',
        href: {
          type: HrefType.External,
          url: 'https://www.infinity.tenset.io/',
          withLocale: false,
        },
      },
      {
        label: 'Incubator',
        href: {
          type: HrefType.Internal,
          url: '/incubator',
          withLocale: true,
        },
      },
      {
        label: 'Governance',
        href: {
          type: HrefType.Internal,
          url: '/governance',
          withLocale: true,
        },
      },
    ],
  },
  {
    label: 'Burns',
    href: {
      type: HrefType.Internal,
      url: '/burns',
      withLocale: true,
    },
  },
  {
    label: 'Roadmap',
    href: {
      type: HrefType.Internal,
      url: '/roadmap',
      withLocale: true,
    },
  },
  {
    label: 'News',
    href: {
      type: HrefType.Internal,
      url: '/news',
      withLocale: true,
    },
  },
  {
    label: 'Contact',
    href: {
      type: HrefType.Internal,
      url: '/contact',
      withLocale: true,
    },
  },
]
