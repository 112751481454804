import { useEffect } from 'react'
import ReactGoogleAnalytics from 'react-ga4'

import { googleAnalyticsConfig, isDevelopment } from '~/config'

const trackingId = googleAnalyticsConfig.id

const googleAnalyticsOptions = {
  testMode: isDevelopment,
}

export function GoogleAnalytics() {
  useEffect(() => {
    ReactGoogleAnalytics.initialize(trackingId, googleAnalyticsOptions)
    ReactGoogleAnalytics.send('pageview')
  }, [])

  return null
}
