import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ConnectWallet } from '~/components/connect-wallet'
import { Namespace } from '~/i18n'
import {
  Button,
  ButtonVariant,
  Card,
  Modal,
  ModalContent,
  ModalMain,
  Text,
} from '~/tenset-components'
import { useWallet } from '~/tenset-web3'

export function AcceptTermsModal() {
  const { t } = useTranslation(Namespace.COMMON)

  const { wallet } = useWallet()

  const [showAcceptTermsModal, setShowAcceptTermsModal] = useState(false)

  useEffect(() => {
    if (!wallet || !wallet.isConnected || !wallet.agreementPromise)
      return setShowAcceptTermsModal(false)

    async function fetchAgreement() {
      return await wallet!.agreementPromise
    }

    fetchAgreement().then(agreement => {
      setShowAcceptTermsModal(!agreement)
    })
  }, [wallet, wallet?.account, wallet?.agreementPromise])

  const termsConditionsUrl =
    'https://www.tenset.io/assets/documents/tenset-terms-and-conditions.pdf'
  const privacyCookiesPolicyUrl =
    'https://www.tenset.io/assets/documents/tenset-privacy-and-cookies-policy.pdf'

  return (
    <Modal open={showAcceptTermsModal}>
      <ModalContent withXCloseButton={false}>
        <ModalMain>
          <Card
            title={t('accept-terms.title')}
            action={
              <div className="flex flex-col gap-2 sm:flex-row">
                <Button
                  onClick={() => wallet?.signAgreement()}
                  loading={wallet?.isProcessing}
                  disabled={wallet?.isProcessing}
                >
                  {t('accept-terms.action')}
                </Button>

                <ConnectWallet />
              </div>
            }
            limitWidth={false}
          >
            <Text>
              <Trans
                t={t}
                i18nKey={`accept-terms.description`}
                components={[
                  <Button
                    key="terms"
                    variant={ButtonVariant.Link}
                    to={termsConditionsUrl}
                  >
                    Terms & Conditions
                  </Button>,
                  <Button
                    key="privacy"
                    variant={ButtonVariant.Link}
                    to={privacyCookiesPolicyUrl}
                  >
                    Privacy & Cookies policy
                  </Button>,
                ]}
                values={{
                  terms: t('footer.legal.terms'),
                  privacy: t('footer.legal.data'),
                }}
              />
            </Text>
          </Card>
        </ModalMain>
      </ModalContent>
    </Modal>
  )
}
