import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'

import { hotjarConfig } from '~/config'

export function Hotjar() {
  useEffect(() => {
    hotjar.initialize(hotjarConfig.id, hotjarConfig.version)
  }, [])
  return null
}
